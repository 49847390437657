import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { getUser, isLoggedIn } from "../services/auth"
import { toast } from "react-toastify"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import PersonalDetails from "../components/checkout/PersonalDetails"
import DeliveryDetails from "../components/checkout/DeliveryDetails"
import { getCheckout, processCheckout } from "../services/checkout"
import PaymentMethod from "../components/checkout/PaymentMethod"
import { syncUser, isGuestRole } from "../services/auth"
import { updateProfile } from "../services/profile"
import SimpleIntro from "../components/global/simple-intro"
import CheckoutLayout from "../components/checkout/checkout-layout"
import CartCheckout from "../components/cart/cart-checkout"
import Button from "../components/button/button"
import InputCheckbox from "../components/form/input-checkbox"
import { eventCheckout, eventPurchase } from "../services/gtm-events"
import { mailchimpOptIn } from "../services/webhooks"

const CheckoutPage = ({ component: Component, location, ...rest }) => {
  const [details, setDetails] = useState(false)
  const [address, setAddress] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [imAnAdult, setImAnAdult] = useState(false)
  const [marketingOptin, setMarketingOptin] = useState(true)
  const [disableProcessCheckout, setDisableProcessCheckout] = useState(true)
  const [amount, setAmount] = useState(0)
  const [shippingAmount, setShippingAmount] = useState(0)
  const [taxAmount, setTaxAmount] = useState(0)
  const [wineryId, setWineryId] = useState(false)
  const [checkout, setCheckout] = useState(false)
  const [error, setError] = useState(false)
  const [processingForm, setProcessingForm] = useState(false)

  //  //get the checkout id in the url
  //   const checkoutId = location.pathname.split("/")[2]
  useEffect(() => {
    if (location.state && location.state.winery_id) {
      // console.log("location.state", location.state)
      handleGetCheckout(location.state.winery_id)
      setWineryId(location.state.winery_id)
      //
    } else {
      navigate("/cart")
    }
  }, [address])

  const handleUpdateUserProfile = async () => {
    const updateProfileData = {
      user: getUser(),
      ...details,
    }

    updateProfile(updateProfileData).then((res) => {
      // console.log("updateProfile() Response: ", res)
      if (res.status) {
        // console.log("onSubmit RES", res)
        const syncGuest = true
        syncUser(true)
      } else if (res.message) {
        toast.error(res.message)
      }
    })
  }

  const handleProcessCheckout = () => {
    let email = false
    let guest = false
    const isGuestUser = isGuestRole()
    if (isGuestUser) {
      const GuestUser = getUser()
      email = GuestUser.email
      guest = true
    }

    handleUpdateUserProfile()
    setDisableProcessCheckout(true)
    setProcessingForm(true)

    // console.log("handleProcessCheckout")
    const user = getUser()

    const processCheckoutData = {
      user: user,
      winery_id: wineryId, //required
      address_id: address.id, //required
      payment_method: paymentMethod.id, //required
      amount: amount, //required
      marketingOptin: marketingOptin,
      shipping_total: shippingAmount || 0,
      tax: taxAmount || 0,
    }
    // console.log("processCheckoutData", processCheckoutData)

    processCheckout(processCheckoutData).then((res) => {
      // console.log("processCheckout Response: ", res)
      if (res.status) {
        eventPurchase(checkout)
        if (marketingOptin) {
          mailchimpOptIn("checkout", email, guest, details)
        }
        navigate("/thank-you", {
          state: {
            email: email,
            guest: guest,
            checkoutData: checkout,
            details: details,
            marketingOptin: marketingOptin,
          },
        })
      } else {
        setDisableProcessCheckout(false)
        setProcessingForm(false)
        toast.success(res.message)
      }
    })
  }

  const handleGetCheckout = (winery_id) => {
    const user = getUser()
    const getCheckoutData = {
      user: user,
      winery_id: winery_id, //required
    }

    getCheckout(getCheckoutData).then((res) => {
      // console.log("getCheckout", res)
      if (res.status) {
        setCheckout(res)
        setAmount(res.data.order_total)
        setShippingAmount(res.data.shipping)
        setTaxAmount(res?.data?.tax)
        eventCheckout(res)
      } else {
        setCheckout(false)
        setAmount(0)
        navigate("/cart")
      }
    })
  }

  useEffect(() => {
    // console.log("details", details)
    // console.log("address", address)
    // console.log("paymentMethod", paymentMethod)
    if (details && address && paymentMethod && imAnAdult) {
      setDisableProcessCheckout(false)
    } else {
      setDisableProcessCheckout(true)
    }
  }, [details, address, paymentMethod, imAnAdult])

  return (
    <Layout>
      <Container width={"medium"} pb={4} gutters>
        <SimpleIntro title={"Checkout"} />
        <CheckoutLayout>
          <div>
            <PersonalDetails setDetails={setDetails} />
            <Container pt={1} pb={1}>
              <DeliveryDetails setAddress={setAddress} />
            </Container>
            <PaymentMethod setPaymentMethod={setPaymentMethod} />
            <InputCheckbox
              text="Please tick this box to confirm you are over 18 years of age"
              checked={imAnAdult}
              onChange={(e) => {
                setImAnAdult(e.target.checked)
              }}
              animate
              errorMessage={!imAnAdult ? "This field is required" : false}
            />
            <InputCheckbox
              text="Thank you for subscribing to receive iHeartWine's exclusive deals and news. If you don't want to hear about the latest wines to hit our cellar or our recommendations, please untick."
              checked={marketingOptin}
              onChange={(e) => {
                setMarketingOptin(e.target.checked)
              }}
              animate
            />
            <ProcessCheckout
              disableProcessCheckout={disableProcessCheckout}
              handleProcessCheckout={handleProcessCheckout}
              processingForm={processingForm}
            />
          </div>
          <div>{checkout && <CartCheckout cart={checkout.data} />}</div>
        </CheckoutLayout>
      </Container>
    </Layout>
  )
}
export default CheckoutPage

const ProcessCheckout = ({
  disableProcessCheckout,
  handleProcessCheckout,
  processingForm,
}) => {
  return (
    <div>
      <Container pt={2} pb={2}>
        {processingForm && (
          <Container textAlign="center">
            <ProceesingIcon />
            <p>Processing checkout... You'll be redirected shortly.</p>
          </Container>
        )}
        {disableProcessCheckout && (
          <div>
            {!processingForm && (
              <Container>
                <p>
                  <small>Please complete all required fields.</small>
                </p>
              </Container>
            )}
          </div>
        )}
        {!processingForm && (
          <Button
            fullWidth
            primary
            onClick={() => {
              handleProcessCheckout()
            }}
            type="submit"
            disabled={disableProcessCheckout || processingForm ? true : false}
          >
            {processingForm ? "Processing..." : "Process Checkout"}
          </Button>
        )}
      </Container>
    </div>
  )
}

const ProceesingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="inline-block"
      width="65px"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#60003a"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.9900990099009901s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  )
}
